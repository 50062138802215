
import { getChartData, getDetails } from '@/api'
import { Toast } from 'vant'
import { defineComponent, nextTick, ref } from 'vue'
import Echarts from './echarts.vue'
import { useRoute } from 'vue-router' // 引用vue-router
export default defineComponent({
  components: {
    Echarts,
  },
  props: {},
  setup() {
    // 接收url里的参数
    const route = useRoute()
    let query: any = route.query
    const show = ref(true)
    const active = ref('a')
    const img = ref('')
    const showPicker = ref(false)
    const date: any = []
    const data: any = []
    const columns: any = []
    const imgList: any = []
    const result = ref('')
    const num = ref('')
    num.value = query.id
    const min = ref(0)
    const max = ref(0)
    const price = (num: string, date1: string) => {
      getChartData(num, date1)
        .then((response) => {
          show.value = false
          // console.log('价格行情走向获取', response)
          const result = response.data.data
          const x = result.x
          const y = result.y
          min.value = result.min
          max.value = result.max
          if (x.length > 0) {
            x.forEach((element: any) => {
              date.push(element)
              // const itemlist = element.split(' ')
              // console.log(itemlist, itemlist[0])
            })
          }
          if (y.length > 0) {
            y.forEach((element: any) => {
              const item = parseInt(element)
              data.push(item)
            })
          }

          setTimeout(() => {
            show.value = true
          }, 500)
        })
        .catch((error) => {
          console.log(error)
          Toast(error + '')
        })
    }
    const imgget = () => {
      getDetails(num.value)
        .then((response) => {
          console.log(response)
          result.value = ''
          const data = response.data.data
          console.log('详情', data)
          result.value = data.name
          img.value = data.pic
          const dateNum = ref('')
          if (active.value === 'a') {
            dateNum.value = '3'
          } else if (active.value === 'b') {
            dateNum.value = '6'
          } else if (active.value === 'c') {
            dateNum.value = '12'
          }
          price(num.value, dateNum.value)
        })
        .catch((error) => {
          console.log(error)
          Toast(error + '')
        })
    }
    imgget()
    show.value = true
    const onClickTab = () => {
      date.splice(0, date.length)
      data.splice(0, data.length)
      if (active.value === 'a') {
        price(num.value, '3')
      } else if (active.value === 'b') {
        price(num.value, '6')
      } else if (active.value === 'c') {
        price(num.value, '12')
      }
    }
    const onConfirm = (value: any) => {
      result.value = value

      date.splice(0, date.length)
      data.splice(0, data.length)
      imgList.forEach((element: any) => {
        if (element.wname === value) {
          img.value = element.pic
          num.value = element.num
          const dateNum = ref('')
          if (active.value === 'a') {
            dateNum.value = '3'
          } else if (active.value === 'b') {
            dateNum.value = '6'
          } else if (active.value === 'c') {
            dateNum.value = '12'
          }
          price(num.value, dateNum.value)
        }
      })
      showPicker.value = false
    }
    return {
      date,
      data,
      active,
      onClickTab,
      show,
      showPicker,
      result,
      columns,
      onConfirm,
      img,
      min,
      max,
    }
  },
})
